.card__container {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    border-radius: 16px;
}

.image__container {
    display: flex;
    justify-content: center;
}

.dash-img {
    width: 84%;
}

.dash-img-onduty {
    width: 66%;
}

.dash-img-Manualentry {
    width: 64%;
}

.feild__container {
    margin-top: -11px;
    padding-bottom: 1px;
}

.feild__container__onduty {
    margin-top: 5px;
    padding-bottom: 1px;
}

.feild__container__Manualentry {
    margin-top: 9px;
    padding-bottom: 1px;
}

.feild__title {
    text-align: center;
}

.feild__value {
    text-align: center;
    font-weight: 700;
}


.card__container:hover {
    background-image: linear-gradient(to bottom, #14a7f994, #0a63f1);
    color: white;
    cursor: pointer
}


/* --------------- Employee Mood Board ------------ */


.Employee__mood__board {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    padding: 20px;
    border-radius: 20px;
    position: relative;
}
.mood__title{
    text-align: center;
    color: #001f55;
    font-weight: 700;
}
.mood__rule{
    padding: 10px;
}
.mood__reactions{
    padding: 3px 25px;
}
.updated__mood{
    padding: 3px 25px;
}
.Mood_update_btn{
    display: flex;
    justify-content: flex-end;
}

.emoji-icon{
    width: 100%;
    cursor: pointer;
}
.mood__all{
    text-decoration: underline;
    text-underline-offset: 10px;
}
.Reacted__emoji{
    text-align: right;
}
.reacted__name{
    font-weight: bold;
}
.emoji-icon-reacted{
    width: 10%;
}
.reacted__name__emoji{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.show__more{
    position: absolute;
    background: linear-gradient(to right, #17bafc, #0a63f1);
    padding: -5px 11px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    border-radius: 0px 0px 20px 20px;
    padding: 0px 43px;
    font-weight: 700;
    text-align: center;
    padding: 8px;
    cursor: pointer;
    color:white
}

/* popup */

.mood__reactions__popup{
    padding: 3px 25px;
}

.mood__all__popup{
    text-decoration: underline;
    text-underline-offset: 13px;
}

.emoji-icon__popup{
    width: 70%;
}

.mood__reactions__popup{
    padding: 3px 25px;
}
.Reacted__emoji__popup{
    text-align: right;
}
.reacted__name__popup{
    font-weight: bold;
}
.emoji-icon-reacted__popup{
    width: 8%;
}
.reacted__name__emoji__popup{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ALL__design{
    display: flex;
    align-items: center;
}
.profile__popup{
    width: 17%;
    border-radius: 50%;
    height: 7vh;
    object-fit: cover;
}
.emoji__count{
    display: flex;
    align-items: center;
}
.emoji__count__value{
    padding-top: 6px;
    padding-left: 4px;
}




/* ------------- announcement ------------- */
.announcement__day{
    width: 35%;
    text-align: end;
}
.announcement__container{
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    padding: 20px;
    height: auto;
    border-radius: 20px;

    max-height: 50vh;
    overflow-y: auto;
}

.announcement__head{
    display: flex;
    justify-content: space-between;
}
.Add__announcement{
    border: 3px solid;
    border-image: linear-gradient(to right, #1aceff, #0056f0) 1;
    background-color: white;
    font-weight: 700;
    font-size: 1vw;
}

/* Scrollbar styles specific to .announcement__container */
.announcement__container::-webkit-scrollbar {
    width: 10px;
}

.announcement__container::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: -webkit-gradient(linear, left top, left bottom, from(#1aceff), to(#0056f0));
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.announcement__container::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
    background: linear-gradient(to right, #ffffff, #c9c9c9 1px, #e9e9e9 1px, #ffffff);
}

.title__day{
    display: flex;
    justify-content: space-between; 
    width: 100%;
}

.announcement__message1{
    background-color: #a7e1f8;
    padding: 12px 20px;
    border-radius: 20px;
    width: 100%;
    word-break: break-word;
}

.announcement__message2{
    background-color: #f2eaaa;
    padding: 12px 20px;
    border-radius: 20px;
}

.announcement__message3{
    background-color: #f2eaaa;
    padding: 12px 20px;
    border-radius: 20px;
}

.message__title{
    font-weight: bold;
    width: 70%;
}

.dashboard-container {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    overflow-x: auto; /* Horizontal scroll if content overflows horizontally */
    overflow-y: auto; /* Vertical scroll if content overflows vertically */
}

.dashboard-content {
    width: max-content; /* Allow content to expand horizontally */
    height: max-content; /* Allow content to expand vertically */
    padding: 20px; /* Adjust padding as needed */
}


/* ----------------------------------------------------------- */

/* user dashboard */

.checkin-btn{
    padding: 13px 45px;
    background: #19cdfe;
    border-color: #19cdfe;
    font-weight: 700;
}

.checkin-btn:hover{
    background: #0962f0;
    border-color: #0962f0;
    
}


.checkout-btn{
    padding: 13px 45px;
    background: #0962f0;
    border-color: #0962f0;
    font-weight: 700;
}
.checkout-btn:hover{
    background: #19cdfe;
    border-color: #19cdfe;
  
}


.checkin__checkout__container{
    display: flex;
   
    column-gap: 25px;
}

.selected {
    background-color: #0962f0;
    border-radius: 27px;
    padding: 10px 10px;
  }
  .reaction__submit__btns{
    display: flex;
    justify-content: flex-end;
    gap: 2%;
  }
  .mood__submit{
    font-weight: bold;
  }
  .mood__submit:hover{
    background-color: white;
    color: #0d6efd;
  }
  .mood__Cancel{
        /* background: blue; */
        background: white;
        color: #0d6efd;
        font-weight: bold;
  }
  .emoji-icon-user{
    width: 65%;
    cursor: pointer;
}
  