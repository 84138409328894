.shift__container {
    padding: 10px 20px;
}

.shift__row {
    background: #ffffff;
    padding: 60px 10px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    margin: 2px;
}

.submit__cancel {
    display: flex;
    gap: 20px;
}

.shift__submit__btn {
    padding: 6px 22px;
    font-weight: bold;
}

.shift__submit__btn:hover {
    background: white;
    color: #0d6efd;
    font-weight: bold;
}

.shift__cancel__btn {
    /* background: blue; */
    background: white;
    color: #0d6efd;
    font-weight: bold;
    padding: 6px 22px;
}

.shift__cancel__btn:hover {
    background: #0d6efd;
}

.thead-dark {
    background-color: #0d6efd;
    color: white;
}

.table {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
}

.btn-view {
    background: rgb(231 224 252);
    border: 1px solid rgb(141 103 254);
    color: black;
    font-weight: 700;
    border-radius: 10px;
}

.btn-download {
    background: rgb(224 241 252);
    border: 1px solid rgb(141 103 254);
    color: #3893cd;
    font-weight: 700;
    border-radius: 10px;
}

.btn-edit {
    background: rgb(240, 246, 229);
    border: 1px solid rgb(118, 183, 0);
    color: black;
    font-weight: 700;
    border-radius: 10px;
}

.btn-delete {
    background: rgb(255, 224, 224);
    border: 1px solid rgb(255, 118, 118);
    color: black;
    font-weight: 700;
    border-radius: 10px;
}