/* Basic Details */

.basic__details__container {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    padding: 30px 43px;
}

/* Employee Details */

.employee__details__container {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    padding: 30px 43px;
}

/* Employee role */

.employee__role__container {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    padding: 30px 43px;
}

/* Bank Details */

.bank__details__container{
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    padding: 30px 43px;
}

/* Documents */

.documents__container{
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    padding: 30px 43px;
}