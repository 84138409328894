/* General styles */
.a4-container {
  width: 8.27in;
  height: 11.69in;
  margin: auto;
  padding: 0.5in 1in 1in 1in;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
}
.a4-container-offer-letter {
  width: 8.27in;
  /* height: 13.69in; */
  margin: auto;
  padding: 0.5in 1in 1in 1in;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
}

.a4-container-appointment {
  width: 8.27in;
  height: 15.69in;
  margin: auto;
  padding: 0.5in 1in 1in 1in;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
}

.content {
  height: calc(100% - 2in);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  margin-bottom: 1in;
}

.logo {
  width: 100%;
  height: 15vh;
  object-fit: contain;
}

.signature-section {
  margin-top: 0.2in;
}

.signature {
  height: 60px;
  width: 150px;
  border-bottom: 1px solid #000;
  margin-bottom: 0.1in;
}

.footer {
  padding-top: 0.3in;
  width: 100%;
  height: 15vh;
  object-fit: contain;
}

.line__height {
  margin-bottom: 15px;
}

.bracket {
  border-bottom: 1px solid #000;
  display: inline-block;
  width: 200px;
  /* Adjust the width as needed */
}

/* -------------------------------------------------------------------- */
/* RelievingLetter.css */


@media print {
  .header {
    position: fixed;
    top: 40px;
    width: 100%;
    left: 0;
    /* height: 200px; */
    padding-left: 30px;
    padding-right: 30px;
  }

  .footer {
    position: fixed;
    bottom: 60px;
    width: 100%;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
  }

  .content {
    margin-top: 200px; 
    /* margin-bottom: 260px;  */
  }

  /* .relieving__letter,
  .appointment__letter {
    margin-top: 100px;
  } */

  .relieving__letter__boday,
  .appointment__letter__boday {
    page-break-inside: avoid;
  }

  .Employment__Terms__Conditions {
    padding-top: 240px; /* This controls the space above the content */
    height: auto; /* Allows the height to adjust based on content */
    overflow-y: auto; /* Adds a scrollbar if content exceeds the height */
    margin-top: 40px; /* Adds space above the element */
}
.accept__Terms__Conditions{
  padding-top: 240px;
}

}
