/* OTPPage.css */
.otp-container {
    max-width: 400px;
    padding: 60px 40px 20px;
    border-radius: 5px;
    background: #77d2fea6;
   
}

.otp-input {
    text-align: center;
    font-size: 20px;
    letter-spacing: 15px;
}

.otp-form-row {
    justify-content: center;
    margin-bottom: 20px;
}

.resend-btn {
    display: block;
    text-align: center;
    margin-top: 20px;
}

.resend-btn.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.OTP__container {
    background-image: url('../../../assets/images/bg.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-bottom-padding {
    padding-bottom: 45px; 
  }
  .resend-btn .btn {
    color: white;
    text-decoration: none;
  }
  
.btn-link{
    color:white;
}
.Verify{
    padding: 8px 35px;
    font-weight: 600;
}