
.custom-modal-backdrop{
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #00000030;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid rgb(171 171 171 / 18%);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}