.dash-title-main{
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 30px;
}
.dash-title{
    color: #737373;
    display: flex;
    justify-content: center;
}


.dash-values{
    font-size: 35px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    color:#5a5a5a;
}
.dash-img{
    width: 76%;
}
.dash-value{
    display: flex;
    align-items: center;
}

.img-alignment{
    display: flex;
    justify-content: center;
}

/* Admindashboard.css */

.admin-dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 32px;
    max-width: 1035px;
    margin: 0 auto;
}
  
  .data-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 0px 10px rgb(0 0 0 / 43%);
    overflow: hidden;
  }
  
  .box-content {
    /* padding: 50px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 25vh; */
    height: auto;
    justify-content: center;
    background-color: white;
  }
  .box-content:hover{
    /* background-color: #212529; */
    background-image: linear-gradient(to bottom, #14a7f994, #0a63f1);

   
  }

  .box-content:hover .dash-title {
    color: white;
}

  
  .box-content i {
    margin-top: 10px;
    font-size: 1.2em;
    animation: bounce 1s infinite alternate; /* Example animation, adjust as needed */
  }
  
  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-5px);
    }
  }


  /* Add the following media query for tablets (portrait and landscape) and larger phones */
@media only screen and (max-width: 768px) {
  .admin-dashboard {
      display: flex;
      flex-direction: column;
  }

  .data-box {
      margin-bottom: 20px;
  }

  .box-content {
      text-align: center;
  }

  .dash-title, .dash-values {
      margin-top: 5px;
      margin-bottom: 5px;
  }
}
  
/* Hrsupport-dashboard */

.TLApproval-dashboard{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 70px;
  max-width: 1035px;
  margin: 0 auto;
}

/* styles.css */
.notification-badge {
  position: relative;
  top: 0px;
  right: 16px;
  background-color: #e94b8e;
  color: white;
  border-radius: 50%;
  padding: 0.4em 0.8em;
  font-size: 0.8em;
  font-weight: bold;
}

.bell{
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  margin-bottom: -70px;
}

/* Tlapproval-dashboard */

.Tlapproval-dashboard{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  max-width: 1035px;
  margin: 0 auto;
}
