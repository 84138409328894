.container {
    margin-top: 16px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
  }
  
  .btn-group {
    display: flex;
    justify-content: space-between;
  }


  
  .tab-button {
    padding: 8px 16px;
    margin-right: 8px;
    border: 1px solid #007bff;
    background-color: white;
    color: #007bff;
    cursor: pointer;
    border-radius: 4px;
    width: 15%;
  }
  
  .tab-button.active, .tab-button:hover {
    background-color: #007bff;
    color: white;
    width: 15%;
  }
  
  .search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 25%;
  }
  
  .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 16px;
    line-height: 2.5;
  }
  
  .card-body-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
  
  .card-title-content {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #3A3A3A;
    line-height: 2.5;
  }
  .card-body-content{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .card-text {
    margin: 4px 0;
  }
  
  .badge {
    padding: 4px 8px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
  }
  
  .badge-success {
    background-color: #28a745;
  }
  
  .badge-secondary {
    background-color: #6c757d;
  }
  
  .details-button {
    padding: 0px 16px;
    border: 1px solid #007bff;
    background-color: white;
    color: #007bff;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .details-button:hover {
    background-color: #007bff;
    color: white;
  }
  .Resume__DataNot{
    text-align: center;
    font-size: 30px;
    height: 10vh;
  }

  .search__btn__group{
    margin-bottom: 60px;
  }

  .candidatestatus{
    width: 100%;
    background: #C6F3FF;
    border: 1px solid #C6F3FF;
    color: #00275C;
    font-weight: 700;
  }