/* ResetPasswordPage.css */

.resetpassword__container{
    background-image: url('../../../assets/images/bg.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reset-form-container{
    max-width: 400px;
    padding: 60px 40px 20px;
    border-radius: 5px;
    background: #77d2fea6;
    position: relative;
}
  
  /* Style the image */
  .image-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Style the form fields */
  .form-control__resetpassword {
    margin-bottom: 20px;
  }
  .Reset_Password{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
.new-eye{
  position: relative;
}
  .new-password-toggle-btn{
    position: absolute;
    top: 5px;
    right: 0px;
  }

  .confirm-eye{
    position: relative;
  }

  .confirm-password-toggle-btn{
    position: absolute;
    top: 5px;
    right: 0px;
  }
  
  